import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
const FullWidthImage = ({ slice }) => {
  // console.log( 'FullWidthImage' , slice )
  return (
    <Box
      // p=".5rem"
      // mt="4rem"
      maxW="1030px" 
      mx="auto"
    >
      <Image
        src={slice.primary.image ? slice.primary.image.url : ''}
        alt={slice.primary.image ? slice.primary.image.alt : ''}
      />
      {slice.primary.legend ?
        <Text
          mt='.5rem'
          fontFamily='Open Sans'
          fontStyle='italic'
          fontSize='14px'
          px={{ base:'2rem', lg:0}}
        >
          { slice.primary.legend }
        </Text>
      : null}
    </Box>
  )
}

export default FullWidthImage
