import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Box, Heading } from '@chakra-ui/react'

const TitleOnly = ({ slice }) => {

  return (
    <Box
      justify="center"
      maxW="1030px"
      mx="auto"
      my="2rem"
      mb="-4rem"
    >
      <Heading
        as="h5"
        fontSize="32px"
        mb="4rem"
        color="brand.orange"
        px={{ base:'1rem', lg:0}}
      >
        {RichText.asText(slice.primary.title_only_content)}
      </Heading>
    </Box>
  )

}


export default TitleOnly
