import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Button, Flex, Heading, Image, SimpleGrid, Stack } from '@chakra-ui/react'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../ButtonPrimary.js'
import getArchivePage from '../../utils/getArchivePage'
import bg from '../../images/background-line-books.svg'
import { useIntl } from 'react-intl'
import Slider from 'react-slick';

const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  vertical: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

const LatestPosts = ({ slice, posts, books }) => (
  <Box
    // backgroundImage={{ lg:'url('+bg+')' }}
    backgroundRepeat='repeat-x'
    backgroundPosition='0 85px'
    py={{ base: 6, lg:0}}
  >
    <Box
      maxW="1030px"
      mx="auto"
      py={{ base:4, lg:0 }}
    >

    <Heading
      as="h3"
      mb={{ base:2, lg: 4 }}
      mx={{ base:4, lg:0 }}
      textTransform="uppercase"
      color="brand.orange"
      fontSize="18px"
      fontFamily="Open Sans"
      // display={{ base: 'none', lg: 'block' }}
    >
      <FormattedMessage id="book.the" />
    </Heading>
      <Slider {...settings}>
        {books.map((book, index) =>
            <Flex px={4} key={`book-${index}`} justify="center" wrap="wrap">
              <Box px="4rem" minH="285px" display="flex" justifyContent="center">
                {book.node.cover ?
                  <Image
                    w={184}
                    h={285}
                    src={book.node.cover.url}
                    alt={RichText.asText(book.node.title)}
                  />
                  : null}
              </Box>
              <Heading
                as="h3"
                fontSize="24px"
                h="64px"
                my="1rem"
                fontWeight="900"
                fontFamily="Open Sans"
                textAlign="center"
              >{RichText.asText(book.node.title)}</Heading>
              <Stack spacing="1rem" w='100%'>
                <Button
                  as={ GatsbyLink }
                  boxShadow="sm"
                  to={linkResolver(book.node._meta)}
                  backgroundColor='brand.orange'
                  border='solid 1px'
                  borderColor='transparent'
                  borderRadius='1px'
                  color='brand.cream'
                  textTransform='uppercase'
                  fontFamily='Open Sans'
                  fontWeight='700'
                  py='1.5rem'
                  w='100%'
                  _hover={{
                    border: "solid 1px",
                    borderColor: "brand.orange",
                    color: "brand.orange",
                    backgroundColor: "brand.cream"
                  }}
                >
                  <FormattedMessage id="main.discover" />
                </Button>
                { book.node.amazon_link ?
                  <Button
                    as='a'
                    href={ book.node.amazon_link.url }
                    target='_blank'
                    rel='noopener'
                    backgroundColor='transparent'
                    py='1.5rem'
                    textTransform='uppercase'
                    border='solid 1px'
                    borderColor='brand.orange'
                    borderRadius='1px'
                    fontFamily='Open Sans'
                    color='brand.orange'
                    _hover={
                      { backgroundColor:'brand.orange',
                        color:'white'}
                    }
                  >
                    <FormattedMessage id="buy.book.on.amazon" />
                  </Button>
                  : null}
              </Stack>
            </Flex>
        )}
      </Slider>
    <Flex pt={{ base:2, lg:4 }} px={{ base:2, lg: 0 }} justify='flex-end'>
      <PrimaryButton to={getArchivePage('book',useIntl()['locale'])}>
        <FormattedMessage id='book.all' />
      </PrimaryButton>
    </Flex>
    </Box>
  </Box>
)

export default LatestPosts
