const config = require('../../config.json');
const { pageUrlBuilder } = require('./funcs');

const conf = {
    'book': pageUrlBuilder(config, 'booksUID'),
    'project': pageUrlBuilder(config, 'projectUID'),
    'commitment': pageUrlBuilder(config, 'commitmentUID'),
}

const getArchivePage = (type, lang) => {
    return(conf[type][lang])
}

export default getArchivePage
