import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { RichText } from 'prismic-reactjs'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Box, Grid, Button, Input, Link, Text } from '@chakra-ui/react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'


import Messages from '../../locales/messages'
import ReactGA from 'react-ga'
ReactGA.initialize(process.env.GA_ID)

const EmailBookBox = ({ slice, bookPdfUrl }) => {
  const intl = useIntl()

  const [stickOnScroll, setStickOnScroll] = useState(true)

  useScrollPosition(({ prevPos, currPos }) => {
    // console.log( 'currPos', currPos)
    const isShow = currPos.y < -600
    if (isShow !== stickOnScroll) setStickOnScroll(isShow)
  }, [stickOnScroll])

  const submitBookForm = (e) => {
    e.preventDefault()

    // downloadFile('coucou', bookPdfUrl, 'b745476c-7598-43fa-8709-c9d66f6bbc33_demo-pdf-file.pdf')
    // bookPdfLink.click();
    let email = document.getElementById('email').value

    addToMailchimp(email,
      {
        BOOK: slice.primary.book_identifier,
        LANG: intl.locale
      }
    ) // listFields are optional if you are only capturing the email address.
      .then(data => {
        ReactGA.event({
          category: 'Newsletter',
          action: 'Subscribe',
          label: 'Footer'
        });
        if (data.result === 'success') {
          // alert('success')
          const bookPdfLink = document.querySelector('#bookPdfLink')
          bookPdfLink.click()
        }
      })
      .catch(() => {
        // Nothing
      })

    // setEmailRecord(true)

  }
  return (
    <Box
      // px={{ base: '2rem', lg: 0 }}
      maxW="1030px"
      mx="auto"
      position={stickOnScroll ? 'fixed' : 'initial'}
      top="0"
      left="0"
      right="0"
      zIndex="tooltip"
      backgroundColor={stickOnScroll ? 'brand.cream' : 'transparent'}
      p={{ base: "1rem", lg: stickOnScroll ? '2rem' : '0' }}
      w="100%"
      mx="auto"
    >
      {slice.primary.text_intro ?
        <Text
          fontSize={{ base: "18px", lg: "24px" }}
          mb="2rem"
          display={stickOnScroll ? "none" : "block"}
          w="100%"
        >
          {RichText.asText(slice.primary.text_intro)}
        </Text>
        : null}

      <Grid
        // columns={{ base:1, lg:2 }}
        templateColumns={{ base: '100%', lg: '70% 30%' }}
        mt={{ base: stickOnScroll ? 0 : "2rem", lg: '0' }}
        as="form"
        onSubmit={(e) => { submitBookForm(e) }}
      >
        <Input
          placeholder={Messages[intl.locale]['footer.your_email']}
          background="transparent"
          borderTop={{ base: "solid 1px", lg: "none" }}
          borderLeft={{ base: "solid 1px", lg: "none" }}
          borderRight={{ base: "solid 1px", lg: "none" }}
          borderBottom={{ base: "solid 1px", lg: "none" }}
          borderColor={stickOnScroll ? 'white' : 'brand.orange'}
          borderRadius="0"
          fontFamily="Open sans"
          textTransform="uppercase"
          color="brand.orange"
          outline="none"
          mb={{ base: stickOnScroll ? '.5rem' : 0, lg: 0 }}
          px={{ base: '1rem', lg: '1rem' }}
          h={{ base: '40px', lg: '60px' }}
          type="email"
          id="email"
          required
          _focus={{
            outline: 'none'
          }}
        />

        <Button
          type="submit"
          backgroundColor={"brand.orange"}
          h={{ base: '40px', lg: '60px' }}
          border="solid 1px"
          borderRadius="1px"
          color={"white"}
          textTransform="uppercase"
          fontFamily="Open Sans"
          fontWeight="700"
          borderColor={stickOnScroll ? 'brand.cream' : 'brand.orange'}
          _hover={{
            border: "solid 1px",
            borderColor: "brand.orange",
            color: "brand.orange",
            backgroundColor: "brand.cream"
          }}
        >
          Recevoir le livre
        </Button>
      </Grid>
      {/*  */}
      <Link display="none" taget="_blank" rel="noopener" id='bookPdfLink' href={bookPdfUrl} download={'Download Book'}>lien</Link>
    </Box>
  )

}


export default EmailBookBox
