import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import topIcon from '../../images/top-icon.png'
import { Box, SimpleGrid } from '@chakra-ui/react'

const TextInfo = ({ slice }) => (
  <SimpleGrid
    px={{ base: '2rem', lg: '0' }}
    maxW="1030px"
    mx="auto"
  >
    <Box className="left-column">
      <img src={topIcon} alt="Checkbox icon" />
      <RichText render={slice.primary.section_title || []} />
      <RichText
        render={slice.primary.left_column_text || []}
        serializeHyperlink={GatsbyLink}
      />
    </Box>
    <Box className="right-column">
      <RichText
        render={slice.primary.right_column_text || []}
        serializeHyperlink={GatsbyLink}
      />
    </Box>
  </SimpleGrid>
)

export default TextInfo
