import React from 'react'
import { Box, Heading, Image, SimpleGrid } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { socials } from '../../../config.json'

import InstagramPost from '../../data/instaPost.json'

const InstagramFeed = ({ slice, allInstaPost }) => {

  return(
    <Box
      px={{ base: '2rem', lg: '0' }}
      maxW="1030px"
      mx="auto"
      fontSize='20px'
    >
      {
        InstagramPost ?
        InstagramPost.graphql ?
        InstagramPost.graphql.user ?
            <>
              <Heading
                as="h5"
                textTransform="uppercase"
                color="brand.orange"
                letterSpacing='.05rem'
                fontFamily='Open Sans'
                fontSize='18px'
                mb='1rem'
              >
                <FormattedMessage id="main.follow_me_instagram" />
              </Heading>
              <SimpleGrid columns={{ base: 1, lg: 3 }} gap="1rem">
            {InstagramPost.graphql.user.edge_owner_to_timeline_media.edges.map((item, index) =>
              index < 3 ?
                <Box
                  key={`instagram-thumbnail-${index}`}
                  as='a'
                  target="_blank"
                  rel="noopener"
                  href={ socials.instagram.link }
                  display={{ base: index > 0 ? 'none' : 'block', lg: 'block' }}
                >
                  <Image src={item.node['thumbnail_src']} alt="Instagram Post" />
                </Box>
                : null

            )}
          </SimpleGrid>
            </>
            : null : null : null }
    </Box>
  )
}

export default InstagramFeed
