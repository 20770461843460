import React from 'react'
import {
  Box,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { Link as GatsbyLink } from 'gatsby'
import PrimaryButton from '../ButtonPrimary.js'
import { useIntl } from 'react-intl'
import getArchivePage from '../../utils/getArchivePage'

const LatestCommitments = ({ slice, commitments }) => {
  if( !commitments || !commitments.length ){
    return null;
  }

  return(
  <Box
    maxW="1030px"
    mx="auto"
    py={{ base:4, lg:0 }}

  >
    <Heading
      as="h3"
      mb={{ base:2, lg: 4 }}
      mx={{ base:4, lg:0 }}
      textTransform="uppercase"
      color="brand.orange"
      fontSize="18px"
      fontFamily="Open Sans"
      // display={{ base: 'none', lg: 'block' }}
    >
      <FormattedMessage id="commitment.the" />
    </Heading>
    {/* <pre>
      { JSON.stringify( commitments, null, 2 )}
    </pre> */}
    <SimpleGrid columns={{ base: 1, lg: 3 }} gap="2rem">
      {commitments.map((item, index) =>
        index < 3 ?
        <Box
          key={`commitment-${index}`}
          // as={GatsbyLink}
          // to={linkResolver(post.node._meta)}
          as={GatsbyLink}
          // to={`/blog/${post.node._meta.uid}`}
          to={linkResolver(item.node)}
          cursor='pointer'
          pb={ 5 }
          borderBottom='solid 3px'
          borderColor='transparent'
          _hover={{
            borderColor:'tomato',
          }}
          role='group'
          // onClick={ () => { navigate( linkResolver(post.node._meta) ) } }
        >
                {/* { JSON.stringify( item , null, 2 )} */}

          <Image
            // display={ slice.primary.show_thumbnails ? 'block' : 'none' }
            objectFit="cover"
            w="100%"
            h="300px"
            src={ item.node.data.featured_image.fixed ? item.node.data.featured_image.fixed.src : '' }
            alt={ item.node.data.featured_image.alt }
          />
          <Stack spacing="1rem" px={{ base:"1rem", lg:'0' }} py="1rem">
            <Heading
              as="h4"
              fontSize={{ base: "3xl", lg:"2xl" }}
              minH={{ lg:"90px" }}

              _groupHover={{ color: 'tomato' }}
            >
              { item.node.data.title.text }
              {/* {RichText.asText(project.node.data.title.raw)} */}
            </Heading>
          </Stack>

        </Box>
        : null
      )}
    </SimpleGrid>
    <Flex pt={{ base:2, lg:4 }} px={{ base:2, lg: 0 }} justify='flex-end'>
      <PrimaryButton
        to={getArchivePage('commitment',useIntl()['locale'])}
      >
        <FormattedMessage id='commitment.all' />
      </PrimaryButton>
    </Flex>

  </Box>
)}

export default LatestCommitments
