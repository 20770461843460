import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import { Box } from '@chakra-ui/react'
import css from './wysiwyg.module.scss'


const Text = ({ slice }) => (
  <Box
    px={{ base: '2rem', lg: '0' }}
    maxW="1030px"
    mx="auto"
    fontSize={{base:'20px', lg:'22px'}}
    // my="1rem"
    className={css.wysiwyg}
  >
    <RichText render={slice.primary.text || []} />
  </Box>
)

export default Text
