import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Flex, Text } from '@chakra-ui/react'
const FullWidthBanner = ({ slice }) => {
  return false
  return (
    <Flex
      backgroundColor="brand.orange"
      color="white"
      justify="center"
      p="1rem"
    >
      <Text
        fontFamily="Open Sans"
        fontWeight="bold"
        fontSize="18px"
        textTransform="uppercase"
      >
        {RichText.asText(slice.primary.banner_title)}
      </Text>
    </Flex>
  )
}

export default FullWidthBanner