import React from 'react'
import { RichText } from 'prismic-reactjs'
// import GatsbyLink from '../GatsbyLink'
import { Box, Text } from '@chakra-ui/react'
import css from './AboutIntro.module.scss'
const AboutIntro = ({ slice }) => (
  <Box
    fontFamily="Vesper Libre"
    fontSize={{ base:"20px", lg:"26px" }}
    lineHeight={{ base:'2rem', lg:'2.2rem' }}

    maxW="1030px"
    mx="auto"
    px={{ base:"1rem", lg:'0'}}
    mt="2rem"
    className={css.aboutIntro}
  >
      {/* <RichText render={slice.primary.first_word || []} /> */}
      {/* <Text as="span" fontSize="32px" fontWeight="900" color="brand.orange">Vitaly Malkin </Text> */}
      <RichText render={slice.primary.intro || []} />
  </Box>
)

export default AboutIntro
