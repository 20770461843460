import React from 'react'

import {
    Box,
    Button,
    Flex,
    Grid,
    Heading,
    Image,
    Stack,
    Text
} from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../ButtonPrimary'
import linkResolver from '../../utils/linkResolver'

const RelatedEntry = ({ slice }) => {
    return (
        <Box
            maxW='1030px'
            mx='auto'
            bg='gray.50'
            border={{ base:'none', lg:'solid 1px'}}
            borderColor={{ base:'transparent', lg:'gray.200' }}
            borderRadius={{ base:0, lg:'sm'}}
            p={{ base: 0, lg: 8 }}
        >
            <Grid templateColumns={{ base: '100%', lg: '20% 1fr' }}>
                <Box>
                    <Image src={slice.primary.link.sharing_image.url} />
                </Box>
                <Flex alignItems='center'>
                    <Stack px={{ base: 6, lg: 6 }} py={{ base:6, lg: 0 }} spacing={{ base: 2, lg: 4 }}>
                        <Box
                            fontSize={{ base: 17, lg: 19 }}
                            textTransform='uppercase'
                        >
                            <Text as="span" color='gray.400'>
                                <FormattedMessage id='main.also.read' />:&nbsp;
                        </Text>
                            <Heading
                                as='h4'
                                display='inline'
                                fontSize={{ base: 17, lg: 19 }}

                            >
                                {slice.primary.title1[0].text}
                            </Heading>
                        </Box>
                        <Box>
                            {slice.primary.teasing[0].text}
                        </Box>
                        <Box>
                        {/* { linkResolver('book', slice.primary.link._meta.uid ) } */}
                            <PrimaryButton
                                to={linkResolver(slice.primary.link._meta)}
                            >
                                <FormattedMessage id='main.discover' />
                            </PrimaryButton>
                        </Box>
                    </Stack>
                </Flex>
            </Grid>
            {/* <pre>
                { JSON.stringify( slice, null, 2 )}
            </pre> */}
        </Box>
    )
}

export default RelatedEntry