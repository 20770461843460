import React from 'react'
import {
    AspectRatio,
    Box
} from '@chakra-ui/react'

const VideoPlayer = (props) => {

    const YoutubeVideo = (props) => {
        // return( <div>{url}</div>)
        console.log( 'data', props.url )
        var regex = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const found = props.url.match(regex);
        let iframeURL = `https://www.youtube.com/embed/${found[2]}?feature=oembed`
        return(
            <AspectRatio ratio={16 / 9}>
                <Box
                    w='100%'
                    h='100%'
                    as='iframe'
                    src={ iframeURL }
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
            </AspectRatio>
        )
    }

    return (
        <Box
            maxW="1030px"
            mx="auto"
            py={{ base: 4, lg: 0 }}

        >
            <pre>
                <YoutubeVideo url={ props.slice.primary.video.embed_url } />
            </pre>
        </Box>
    )
}

export default VideoPlayer