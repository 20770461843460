import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link as GatsbyLink} from 'gatsby'
import { Box, Button, Flex, Grid, Image } from '@chakra-ui/react'
import css from './wysiwyg.module.scss'
import bioPortrait from '../../images/vitaly-malkin-portrait-square.jpg'
// import { FormattedMessage } from 'react-intl'
import { linkResolver } from 'gatsby-source-prismic-graphql'

const Bio = ({ slice }) => (
  <Box
    px={{ base: '2rem', lg: '0' }}
    maxW="1030px"
    mx="auto"
    fontSize='20px'
    // my="1rem"
    my="3rem"
  >
    <Grid templateColumns={{ base:'100%', lg:'1fr 240px' }} gridGap="3rem">
      <Flex alignItems="center">
      <Box fontSize="19px">
        <Box className={css.wysiwyg} mb='2rem'>
          <RichText render={slice.primary.bio_content || []} />
        </Box>
        {/* <Button
          as={ GatsbyLink}
          fontFamily="Open Sans"
          textTransform="uppercase"
          backgroundColor="brand.orange"
          borderRadius="1px"
          py="1.75rem"
          px='3rem'
          boxShadow="sm"
          to={`${linkResolver(slice.primary.button_target._meta)}`}
          border="solid 1px"
          borderColor="brand.orange"
          color='brand.cream'
          w={{ base:'100%', lg:'auto'}}
          _hover={{
            border: "solid 1px",
            borderColor: "brand.orange",
            color: "brand.orange",
            backgroundColor: "brand.cream"
          }}
        >{slice.primary.button_label}</Button> */}
      </Box>
      </Flex>
      <Box>
          <Image
            display={{ base:'none', lg:'initial'}}
            w='100%'
            height='240px'
            objectFit="cover"
            src={bioPortrait}
            alt="Vitaly Malkin"
          />
      </Box>
    </Grid>
  </Box>
)

export default Bio
