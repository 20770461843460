import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Box, Flex, Heading, Image, SimpleGrid, Stack } from '@chakra-ui/react'
import { FormattedMessage } from 'react-intl'
import { linkResolver } from 'gatsby-source-prismic-graphql'
import { Link as GatsbyLink } from 'gatsby'
import PrimaryButton from '../ButtonPrimary'

const filterPosts = (posts) => {
  let filteredPposts = []
  for (let index = 0; index < posts.length; index++) {
    const post = posts[index];
    if( post.node.is_visible || post.node.is_visible === null ){
      filteredPposts.push( post )
    }
  }
  return filteredPposts
}

const LatestPosts = ({ slice, posts }) => {
  const hasPosts = posts && posts.length;

  if (!hasPosts) {
    return null;
  }

  return(
  <Box
    maxW="1030px"
    mx="auto"
    py={{ base:4, lg:0 }}

  >
    <Heading
      as="h3"
      mb={{ base:2, lg: 4 }}
      mx={{ base:4, lg:0 }}

      textTransform="uppercase"
      color="brand.orange"
      fontSize="18px"
      fontFamily="Open Sans"
      // display={{ base: 'none', lg: 'block' }}
    >
      <FormattedMessage id="post.the" />
    </Heading>
    <SimpleGrid columns={{ base: 1, lg: 3 }} gap="2rem">
      {filterPosts(posts).map((post, index) =>
        index < 3 ?
        <Box
          key={`post-${index}`}
          // as={GatsbyLink}
          // to={linkResolver(post.node._meta)}
          as={GatsbyLink}
          // to={`/blog/${post.node._meta.uid}`}
          to={linkResolver(post.node._meta)}
          cursor='pointer'
          pb={ 5 }
          borderBottom='solid 3px'
          borderColor='transparent'
          _hover={{
            borderColor:'tomato',
          }}
          role='group'
          // onClick={ () => { navigate( linkResolver(post.node._meta) ) } }
        >
          <Image
            display={ slice.primary.show_thumbnails ? 'block' : 'none' }
            objectFit="cover"
            w="100%"
            h="300px"
            src={post.node.featured_image ? post.node.featured_image.url : ''}
            alt="Vitaly"
          />
          <Stack spacing="1rem" px={{ base:"1rem", lg:'0' }} py="1rem">
            <Heading
              as="h4"
              fontSize={{ base: "3xl", lg:"2xl" }}
              minH={{ lg:"90px" }}

              _groupHover={{ color: 'tomato' }}
            >
              {RichText.asText(post.node.title)}
            </Heading>
          </Stack>

        </Box>
        : null
      )}
    </SimpleGrid>
    <Flex pt={{ base:2, lg:4 }} px={{ base:2, lg: 0 }} justify='flex-end'>
      <PrimaryButton
        to={ '/blog' }
      >
        <FormattedMessage id='post.all' />
      </PrimaryButton>
    </Flex>

  </Box>
)}

export default LatestPosts
