import React from 'react'
import { Button } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

const PrimaryButton = ( props ) => {
    return(
        <Button
            to={ props.to }
            as={ GatsbyLink }
            bg='brand.orange'
            color='white'
            borderRadius='2px'
            border='solid 1px'
            borderColor='brand.orange'
            _hover={{
                bg:'white',
                border:'solid 1px',
                borderColor:'brand.orange',
                color:'brand.orange'
            }}
        >
            { props.children }
        </Button>
    )
}

export default PrimaryButton