import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import EmailBookBox from '../EmailBookBox'
import { Box, Flex, Image, SimpleGrid } from '@chakra-ui/react'
import css from './wysiwyg.module.scss'

const InfoWithImage = ({ slice, bookPdfUrl }) => {
  const featuredImage = slice.primary.featured_image
  return (
    <Box
      px={{ base: '2rem', lg: '0' }}
      maxW="1030px"
      mx="auto"
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap="1.5rem">
        <Box>
          <picture>
            <Image
              objectFit="cover"
              w={{ base: '290px', lg: 'auto' }}
              mx='auto'
              src={featuredImage ? featuredImage.url : ''}
              alt={featuredImage ? featuredImage.alt : ''}
            />
          </picture>
        </Box>
        <Flex fontSize="19px" alignItems="center" wrap='wrap'>
          <Box mb='1rem'>
            <RichText render={slice.primary.section_title || []} />
            <Box
                className={css.wysiwyg}
            >
              <RichText
                render={slice.primary.text || []}
                serializeHyperlink={GatsbyLink}
              />
            </Box>

          </Box>
          {
            slice.primary.show_email_box ?
              <EmailBookBox
                textIntro={slice.primary.text_intro}
                bookIdentifier={slice.primary.book_identifier}
                bookPdfUrl={bookPdfUrl}
              />
            : null
          }
        </Flex>
      </SimpleGrid>
    </Box>
  )
}

export default InfoWithImage
