import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Box, Text, Heading } from '@chakra-ui/react'
const Quote = ({ slice }) => {
  return (
    <Box
      px={{ base:'2rem', lg:'0' }}
      maxW="1030px" 
      mx="auto"
    >
      <Heading
        pt='2rem'
        as="h1"
        fontWeight='900'
        pr={{ lg:'4rem' }}
        fontSize={{ base:'3xl', lg:'5xl' }}
      >
        {RichText.asText(slice.primary.title1)}
      </Heading>
    </Box>
  )
}

export default Quote
