import React from 'react'
import { RichText } from 'prismic-reactjs'
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Button } from '@chakra-ui/react'
import { linkResolver } from 'gatsby-source-prismic-graphql'

const CenteredButton = ({ slice }) =>{
  // console.log( 'slice button', slice )

  return(
    <Flex
      justify="center"
      maxW="1030px"
      mx="auto"
      my="2rem"
      px={{ base: '2rem', lg: '0' }}
    >
      <Button
        as={ slice.primary.link.url ? 'a' : GatsbyLink }
        to={ slice.primary.link.url ? slice.primary.link.url : linkResolver(slice.primary.link._meta) }
        href={ slice.primary.link.url ? slice.primary.link.url : linkResolver(slice.primary.link._meta) }
        backgroundColor={"brand.orange"}
        border="solid 1px"
        borderColor="transparent"
        borderRadius="1px"
        borderRadius="1px"
        color={ "white" }
        textTransform="uppercase"
        fontFamily="Open Sans"
        fontWeight="700"
        py='1.5rem'
        boxShadow='sm'
        whiteSpace='pre-wrap'
        textAlign='center'
        _hover={{
          border: "solid 1px",
          borderColor: "brand.orange",
          color: "brand.orange",
          backgroundColor: "brand.cream"
        }}
      >
        {RichText.asText(slice.primary.title1)}
      </Button>
    </Flex>
  )
  
}


export default CenteredButton
