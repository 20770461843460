import React from 'react'
import { Box } from '@chakra-ui/react'
import {
  Button,
  EmailSignup,
  FullWidthImage,
  FullWidthBanner,
  HeadlineWithButton,
  InfoWithImage,
  TextInfo,
  Quote,
  Text,
  AboutIntro,
  LatestPosts,
  LatestBooks,
  LatestProjects,
  LatestCommitments,
  TwoCols,
  EmailBookBox,
  InstagramFeed,
  TitleOnly,
  Bio,
  RelatedEntry,
  VideoPlayer
} from './slices'

const SliceZone = ({
  slices,
  posts,
  projects,
  commitments,
  books,
  bookPdfUrl,
  allInstaPost
 }) => {
  const sliceComponents = {
    headline_with_button: HeadlineWithButton,
    email_signup: EmailSignup,
    full_width_image: FullWidthImage,
    full_width_banner:FullWidthBanner,
    info_with_image: InfoWithImage,
    text_info: TextInfo,
    text: Text,
    about_intro: AboutIntro,
    title_only: TitleOnly,
    books: LatestBooks,
    latest_posts: LatestPosts,
    latest_projects: LatestProjects,
    latest_commitments: LatestCommitments,
    commitments: LatestCommitments,
    quote: Quote,
    two_cols: TwoCols,
    button: Button,
    email_book_box: EmailBookBox,
    instagram_feed: InstagramFeed,
    bio: Bio,
    relatedEntryBlock: RelatedEntry,
    youtube_player: VideoPlayer
  }
  if( !slices ){ return null }
  // console.log( 'slices', slices )
  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.type]
    if (SliceComponent) {
      return (
        <Box pb={{ base:'2rem', lg:'3.5rem'}} key={`${slice.__typename}-${index}`}>
          {/* <Box>Component : {slice.__typename}</Box> */}
          <SliceComponent
            slice={slice}
            posts={posts}
            books={books}
            projects={projects}
            commitments={commitments && commitments.edges ? commitments.edges : commitments }
            bookPdfUrl={bookPdfUrl}
            allInstaPost={ allInstaPost }
            key={`slice-${index}`}
          />
        </Box>
      )
    }else{
      // <Box>Component : {slice.__typename}</Box>
    }
    // return null
    return null
  })
}

export default SliceZone
