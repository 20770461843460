import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react'

const TwoCols = ({ slice }) => {
  const featuredImage = slice.primary.featured_image
  return (
    <Box
      px={{ base: '2rem', lg: '0' }}
      maxW="1030px"
      mx="auto"
      fontFamily="Vesper Libre"
      pb="4rem"
      borderBottom="solid 2px #CCC"
    >
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap="4rem">
        <Box fontSize="20px">
          <RichText
            render={slice.primary.left_text || []}
            serializeHyperlink={GatsbyLink}
          />
        </Box>
        <Flex
        wrap="wrap"
        alignContent="center"
          fontSize="22px"
          color="brand.orange"
          >
            <Box>         <RichText
            render={slice.primary.right_text || []}
            serializeHyperlink={GatsbyLink}
          />
          </Box>
 
          <Box>
          <Box mt="1rem" h="1px" w="25px" backgroundColor="brand.orange" />
          <Text mt="1rem" textTransform="capitalize">
            {RichText.asText(slice.primary.right_text_footer)}
          </Text>
          </Box>
          
        </Flex>
      </SimpleGrid>
    </Box>
  )
}

export default TwoCols
